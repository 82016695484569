import cx from 'classnames';
import React, { cloneElement } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './MenuOverlay.scss';
import { Icon } from '../../Icon/Icon';

let keyCounter = 1;

export interface Option {
  /** If true, close menu on selection. @default true */
  closeMenuOnSelection?: boolean;
  /** The icon and text color.
   * - Red: for destructive actions.
   * - Pink: for Kindly-employee-only actions.
   */
  color?: 'red' | 'pink';
  /** If true, the option will appear as disabled. */
  disabled?: boolean;
  /** The option icon. */
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  /** Function to call when the option is selected. */
  onClick?: ((event: React.MouseEvent<HTMLButtonElement>, option: Option) => void) | (() => void);
  /** The option name. */
  text: string;
  /** Relative URL to navigate to, using React Router. */
  to?: string;
  key?: string;
  title?: string;
  divider?: boolean;
  className?: string;
}

export type MenuOverlayOptions = Array<Option | 'separator' | React.ReactElement>;

interface Props {
  /** Function passed from <Dropdown> and <ContextMenu> to close it. */
  close?: () => void;
  options: MenuOverlayOptions;
  wrapperClassName?: string;
}

/** **Template overlay for menu-like components**
 *
 * In an `option` object, in addition to some custom props, you can pass any props
 * that you would pass to a react `<button>` element.
 */
function MenuOverlay({ options, close, wrapperClassName }: Props) {
  const navigate = useNavigate();

  return (
    <ul className={cx(styles.wrapper, wrapperClassName)}>
      {options.map((option) => {
        if (option === 'separator') {
          return <li key={`separator_${keyCounter++}`} className={styles.separator} />;
        }

        if (React.isValidElement(option) || 'props' in option) {
          return <li key={`element_${keyCounter++}`}>{cloneElement(option as React.ReactElement, { close })}</li>;
        }

        const { text, icon, onClick, color, to, closeMenuOnSelection = true, className, ...restButtonProps } = option;
        const handleOptionClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
          onClick?.(event, option);

          if (closeMenuOnSelection) close?.();
          if (to) navigate(to);
        };
        return (
          <li key={text} className={styles.li}>
            <button
              {...restButtonProps}
              type="button"
              className={cx(styles.option, className, {
                [styles.redText]: color === 'red',
                kindlyAdminManager: color === 'pink',
              })}
              onClick={handleOptionClick}
            >
              {icon && <Icon component={icon} className={styles.icon} />}
              {text}
            </button>
          </li>
        );
      })}
    </ul>
  );
}

export default MenuOverlay;
