import { useMutation, useQuery } from '@apollo/client';
import cx from 'classnames';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  type ModerationFilterChoices,
  NlpBotCustomizationDocument,
  UpdateNlpBotCustomizationDocument,
} from 'frontend/api/generated';
import { CheckboxOnly, Panel } from 'frontend/components';
import InputRHF from 'frontend/components/Input/InputRHF';
import PageBarRHF from 'frontend/components/PageBar/PageBarRHF';
import SelectRHF from 'frontend/components/Select/SelectRHF';

import styles from './BotConfiguration.scss';
import ToneOfVoiceRadio, { toneOptions } from './ToneOfVoiceRadio';

interface BotCustomizationFormData {
  botName: string;
  companyName: string;
  companyDomain?: string;
  botMission?: string;
  responseFormat: 'QUICK_CHAT' | 'CUSTOMER_SUPPORT' | 'INTERNAL_SUPPORT';
  toneOfVoice: keyof typeof toneOptions;
  moderationFilters: ModerationFilterChoices[];
}

export default function BotConfigurationView() {
  const { botId = '' } = useParams();
  const { data } = useQuery(NlpBotCustomizationDocument, {
    variables: { botId },
  });
  const [updateNLPBotCustomization] = useMutation(UpdateNlpBotCustomizationDocument);

  const rhfMethods = useForm<BotCustomizationFormData>({
    values: {
      botName: data?.bot?.nlpSettings?.botCustomizationBotName || '',
      companyName: data?.bot?.nlpSettings?.botCustomizationCompanyName || '',
      companyDomain: data?.bot?.nlpSettings?.botCustomizationCompanyDomain || '',
      botMission: data?.bot?.nlpSettings?.botCustomizationBotMission || '',
      responseFormat: data?.bot?.nlpSettings?.botCustomizationResponseFormat || 'QUICK_CHAT',
      toneOfVoice: data?.bot?.nlpSettings?.botCustomizationToneOfVoice || 'FORMAL',
      moderationFilters: (data?.bot?.nlpSettings?.moderationFilters as ModerationFilterChoices[]) || [],
    },
  });

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
    reset,
    watch,
  } = rhfMethods;

  const watchToneOfVoice = watch('toneOfVoice');

  const onSubmit = async (formData: BotCustomizationFormData) => {
    const { data: botCustomizationAfterMutation } = await updateNLPBotCustomization({
      variables: {
        botId,
        ...formData,
      },
    });

    reset({
      botName: botCustomizationAfterMutation?.updateNlpBotCustomization?.botCustomizationBotName || '',
      companyName: botCustomizationAfterMutation?.updateNlpBotCustomization?.botCustomizationCompanyName || '',
      companyDomain: botCustomizationAfterMutation?.updateNlpBotCustomization?.botCustomizationCompanyDomain || '',
      botMission: botCustomizationAfterMutation?.updateNlpBotCustomization?.botCustomizationBotMission || '',
      responseFormat:
        botCustomizationAfterMutation?.updateNlpBotCustomization?.botCustomizationResponseFormat || 'QUICK_CHAT',
      toneOfVoice: botCustomizationAfterMutation?.updateNlpBotCustomization?.botCustomizationToneOfVoice || 'FORMAL',
      moderationFilters:
        (botCustomizationAfterMutation?.updateNlpBotCustomization?.moderationFilters as ModerationFilterChoices[]) ||
        [],
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageBarRHF>
        <PageBarRHF.FormButtons isDirty={isDirty} reset={reset} isSubmitting={isSubmitting} />
      </PageBarRHF>

      <Panel>
        <header className={styles.headerSection}>
          <h3 className="font-weight-medium">Bot persona</h3>
          <p>
            This section lets you define your bot&apos;s personality. Customize these settings to ensure your bot
            communicates in a style that aligns with your brand and effectively engages your audience.{' '}
            <em>(Currently applies to Kindly GPT, Smart Replies, Smart Webhook and Small Talk)</em>
          </p>
        </header>

        <div className={styles.inputsWrapper}>
          <div>
            <InputRHF
              name="botName"
              label="Name"
              placeholder="E.g. FunBot"
              maxLength={50}
              inputLimit={50}
              rhfOptions={{ required: true, maxLength: 50 }}
              rhfMethods={rhfMethods}
              fieldColor="mischka"
            />
            <small className={styles.smallText}>Let the bot know how to refer to itself in the chat</small>
          </div>

          <div>
            <InputRHF
              name="companyName"
              label="Company name"
              placeholder="E.g. The fun company"
              inputLimit={50}
              rhfOptions={{ required: true, maxLength: 50 }}
              rhfMethods={rhfMethods}
              fieldColor="mischka"
            />
            <small className={styles.smallText}>Let the bot know how to refer to the company in the chat</small>
          </div>

          <div>
            <InputRHF
              name="companyDomain"
              label="Company domain"
              placeholder="E.g. The fun invoices department"
              inputLimit={50}
              rhfOptions={{ maxLength: 50 }}
              rhfMethods={rhfMethods}
              fieldColor="mischka"
            />
            <small className={styles.smallText}>
              Let the bot know the company department or domain the bot operates in
            </small>
          </div>
        </div>

        <Panel.Separator />

        <div className={styles.headerSection}>
          <h3 className="font-weight-medium">Bot strategy</h3>
          <p>
            Define your bot&apos;s mission here to set its purpose, objectives, and overall role in user interactions.{' '}
            <em>(Currently applies to Kindly GPT, Smart Replies, Smart Webhook and Small Talk)</em>
          </p>
        </div>

        <div className={styles.maxWidth550}>
          <InputRHF
            name="botMission"
            label="Bot's mission"
            placeholder="E.g. To be the best and most fun helper in town"
            inputLimit={150}
            rhfOptions={{ maxLength: 150 }}
            rhfMethods={rhfMethods}
            fieldColor="mischka"
          />
          <small className={styles.smallText}>Describe what the goal of the bot is</small>
        </div>

        <Panel.Separator />

        <div className={styles.headerSection}>
          <h3 className="font-weight-medium">Bot personality</h3>
          <p>
            Here, you can customize your bot&apos;s personality to better fit your needs. Adjust its tone and level of
            details to create a more personalized and engaging experience.{' '}
            <em>(Currently applies to Kindly GPT, Smart Replies, Smart Webhook and Small Talk)</em>
          </p>
        </div>

        <div className={styles.blockContainingSelect}>
          <SelectRHF
            name="responseFormat"
            label="Response format"
            rhfMethods={rhfMethods}
            rhfOptions={{ required: true }}
            fieldColor="mischka"
          >
            <option value="QUICK_CHAT">Short and concise responses</option>
            <option value="CUSTOMER_SUPPORT">Medium length responses</option>
            <option value="INTERNAL_SUPPORT">Long and detailed responses</option>
          </SelectRHF>
          <small className={styles.smallText}>Set the response format you want the bot to have</small>
        </div>

        <div className={cx(styles.maxWidth550, 'm-t-6')}>
          <label>Tone of voice</label>
          <ToneOfVoiceRadio name="toneOfVoice" control={rhfMethods.control} />
          <small className={styles.smallText}>
            <b>{toneOptions[watchToneOfVoice].label}</b>: {toneOptions[watchToneOfVoice].labelExplanation}
          </small>
          <small className={styles.smallText}>E.g. &quot;{toneOptions[watchToneOfVoice].exampleText}&quot;</small>
        </div>

        <Panel.Separator />

        <div className={styles.headerSection}>
          <h3 className="font-weight-medium">Bot moderation</h3>
          <p>
            This section allows you to moderate which user messages the bot will not reply to (ie send to fallback). By
            checking the categories you want to moderate, the bot will not reply to messages that match the selected
            categories.
          </p>
        </div>

        <div>
          <fieldset className="all-unset">
            <legend className={styles.sectionTitle}>Filters</legend>
            <div className={styles.checkboxes}>
              <label className={styles.label}>
                <CheckboxOnly rhfMethods={rhfMethods} value="WEAPONS" name="moderationFilters" />
                Weapons
              </label>{' '}
              <label className={styles.label}>
                <CheckboxOnly rhfMethods={rhfMethods} value="DRUGS" name="moderationFilters" />
                Drugs
              </label>{' '}
              <label className={styles.label}>
                <CheckboxOnly rhfMethods={rhfMethods} value="SEXUAL" name="moderationFilters" />
                Sexual
              </label>{' '}
              <label className={styles.label}>
                <CheckboxOnly rhfMethods={rhfMethods} value="HARASSMENT" name="moderationFilters" />
                Harassment
              </label>{' '}
              <label className={styles.label}>
                <CheckboxOnly rhfMethods={rhfMethods} value="PROFANITY" name="moderationFilters" />
                Profanity
              </label>
            </div>
          </fieldset>
          <small className={styles.smallText}>Check the categories you would like to moderate</small>
        </div>
      </Panel>
    </form>
  );
}
